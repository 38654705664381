import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
import { JoinLeft, JoinRight, JoinInner, JoinOuter, JoinFull } from '@carbon/icons-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Column = makeShortcode("Column");
const ExpressiveListContainer = makeShortcode("ExpressiveListContainer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Joins`}</h2>

    <Column mdxType="Column">
  <ExpressiveListContainer title="Left join" mdxType="ExpressiveListContainer">
    <JoinLeft size="28" style={{
          display: "block",
          margin: "0 auto 0 0"
        }} mdxType="JoinLeft" />
    <p>
      All rows from the left table and the matching rows from the right table. If a row in the left table does not have
      a match in the right table, the result will contain null values for all columns of the right table.
    </p>
  </ExpressiveListContainer>
  <ExpressiveListContainer title="Right join" mdxType="ExpressiveListContainer">
    <JoinRight size="28" style={{
          display: "block",
          margin: "0 auto 0 0"
        }} mdxType="JoinRight" />
    <p>
      All rows from the right table and the matching rows from the left table. If a row in the right table does not have
      a match in the left table, the result will contain null values for all columns of the left table.
    </p>
  </ExpressiveListContainer>
  <ExpressiveListContainer title="Inner join" mdxType="ExpressiveListContainer">
    <JoinInner size="28" style={{
          display: "block",
          margin: "0 auto 0 0"
        }} mdxType="JoinInner" />
    <p>
      Matching rows of both tables. If a row in either table does not have a match in the other table, that row will not
      be included in the result set.
    </p>
  </ExpressiveListContainer>
  <ExpressiveListContainer title="Outer join (left or right)" mdxType="ExpressiveListContainer">
    <JoinOuter size="28" style={{
          display: "block",
          margin: "0 auto 0 0"
        }} mdxType="JoinOuter" />
    <p>
      All rows from one table and the matching rows from another table. If a row in one table does not have a match in
      the other table, the result will contain null values for all columns of the other table.
    </p>
  </ExpressiveListContainer>
  <ExpressiveListContainer title="Full outer join" mdxType="ExpressiveListContainer">
    <JoinFull size="28" style={{
          display: "block",
          margin: "0 auto 0 0"
        }} mdxType="JoinFull" />
    <p>
      All rows from both tables, whether there is a match or not. Any unmatched rows from either table will be
      represented by null values in the result set.
    </p>
  </ExpressiveListContainer>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      